/******************************************************
NAV
******************************************************/

a#left-menu {
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  text-align: center;
  display: inline-block;
  color: white;
  text-decoration: none;
  background: #366DB3;
  background: rgba(54, 109, 179, 1);
  border-radius: 0 6px 6px 0;
}

a#left-menu.active {
  position: absolute;
  left: 0;
  top: 50px;
}

a#left-menu::before,
a#left-menu.active::before {
  content: '\e811';
  font-family: 'luigimorini-icons';
  font-size: 1.875em;
  line-height: 50px;
  font-weight: normal;
  text-decoration: none;
}

a#left-menu.active::before {
  content: '\e811';
}

.sidr.left {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 12.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background: #366DB3;
  background: rgba(54, 109, 179, 1);
  color: white;
  left: -12.5em;
  right: auto;
  padding: 50px 0 0;
}

#navigation {
  width: 100%;
  font-size: 1em;
  text-align: left;
}

#navigation h2 {
  color: white;
}

#navigation ul {
  margin: 0 10px 25px;
}

#navigation li {
  display: block;
  text-align: left;
}

#navigation li#menu-item-87 {
  display: none;
}

#navigation li a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 0;
  height: 25px;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.75s;
  border-bottom: white 1px solid;
  margin: 0 0 1px;
}

#navigation li a:hover,
#navigation li.current-menu-item a,
#navigation li.current-page-ancestor a {
  background: #326CB3;
  color: white;
  opacity: 0.5;
}

#navigation #nav-general a {
  font-size: 0.875em;
  text-transform: none;
  letter-spacing: 0;
}

div#mlp_widget-2 {
  text-align: center;
  margin-bottom: 40px;
}

#navigation div#mlp_widget-2 li,
#navigation div#mlp_widget-2 li a {
  display: inline;
  padding: 0;
  margin: 0 5px;
  text-decoration: none;
  border-bottom: none;
}

#navigation div#mlp_widget-2 li a:hover,
#navigation div#mlp_widget-2 li.current a {
  text-decoration: underline;
  color: #326CB3;
  background: none;
}

/* SM BUTTONS */

#text-3,
#text-2 {
  margin: 40px auto;
  width: 104px;
}

.addthis_toolbox {
  text-align: center;
}

#text-3 h2,
#text-2 h2 {
  display: none;
}

div#mlp_widget-2 img {
  width: 24px;
  height: 16px;
}

@media only screen and (orientation: landscape) and (min-width: 801px) {

  /******************************************************
NAV
******************************************************/

  a#left-menu {
    display: none;
  }

  .sidr.left {
    background: rgba(54, 109, 179, 1);
    height: 100%;
    width: 12.5em;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 1em;
    z-index: 11;
    display: block;
    overflow: hidden;
  }

  #navigation {
    margin: 0;
  }

  .nav-center {
    position: absolute;
    left: 0;
    top: 40%;
    bottom: 50%;
    margin-top: -10em;
    height: 20em;
    width: 12.5em;
    border-bottom: none;
  }

  #navigation ul {
    margin: 0 0 1.25em;
    float: none;
  }

  #nav_menu-2,
  #nav_menu-3 {
    float: none;
    margin: 0 0 1.25em;
    width: 100%;
  }

  #nav_menu-4 {
    position: static;
    background: none;
    margin: 0;
    width: 100%;
    text-align: left;
    height: auto;
  }

  #navigation #nav_menu-4 li {
    display: block;
  }

  #navigation #nav_menu-4 ul {
    margin: 0;
  }

  #navigation #nav_menu-4 h2 {
    display: none;
  }

  #navigation li {
    display: block;
    padding: 0;
  }

  #navigation li a {
    color: white;
    padding: 0 10%;
    height: 1.25em;
    line-height: 1.25em;
    transition: background-color 0.75s;
    margin: 0 0 1px;
    border-bottom: none;
  }

  #navigation li a:hover,
  #navigation li.current-menu-item a,
  #navigation li.current-page-ancestor a {
    background: white;
    color: #326CB3;
    opacity: 1;
  }

  #navigation #nav_menu-4 a {
    font-size: 0.875em;
    text-transform: none;
  }

  #navigation div#mlp_widget-2 {
    position: fixed;
    bottom: 50px;
    left: 5%;
    right: auto;
    top: auto;
    width: 12.5em;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin: 0;
    font-size: 1em;
  }

  div#mlp_widget-2 img {
    width: 24px;
    height: 16px;
  }

  p.copy {
    color: #326CB3;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media only screen and (orientation: landscape) and (min-width: 801px) and (max-height: 599px) {

  .nav-center {
    position: absolute;
    top: inherit;
    bottom: 0;
    height: auto;
    margin-top: 0;
  }

  #nav_menu-2,
  #nav_menu-3 {
    margin: 0;
  }

  #navigation li a {
    height: 1em;
    line-height: 1em;
    margin: 0 0 1px;
    font-size: 0.875em;
  }
}

.lang-item > a {
  display: initial !important;
}
