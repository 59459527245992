/******************************************************
FONTS
******************************************************/

@font-face {
  font-family: 'corporatesttuligregular';
  src: url('fonts/urw_-_corporatesttulig-webfont.eot');
  src: url('fonts/urw_-_corporatesttulig-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/urw_-_corporatesttulig-webfont.woff') format('woff'),
    url('fonts/urw_-_corporatesttulig-webfont.ttf') format('truetype'),
    url('fonts/urw_-_corporatesttulig-webfont.svg#corporatesttuligregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'luigimorini-icons';
  src: url('fonts/luigimorini-icons.eot?65691088');
  src: url('fonts/luigimorini-icons.eot?65691088#iefix') format('embedded-opentype'),
    url('fonts/luigimorini-icons.woff?65691088') format('woff'),
    url('fonts/luigimorini-icons.ttf?65691088') format('truetype'),
    url('fonts/luigimorini-icons.svg?65691088#luigimorini-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'luigimorini-icons';
    src: url('luigimorini-icons.svg?65691088#luigimorini-icons') format('svg');
  }
}
*/

[class^='icon-']::before,
[class*=' icon-']::before {
  font-family: 'luigimorini-icons';
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;

  /* opacity: .8; */

  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
}

.icon-basket::before { content: '\e808'; } /* '' */
.icon-search::before { content: '\e800'; } /* '' */
.icon-resize-small::before { content: '\e80a'; } /* '' */
.icon-resize-full::before { content: '\e809'; } /* '' */
.icon-cancel::before { content: '\e804'; } /* '' */
.icon-plus::before { content: '\e805'; } /* '' */
.icon-ok::before { content: '\e803'; } /* '' */
.icon-location-circled::before { content: '\e813'; } /* '' */
.icon-location::before { content: '\e812'; } /* '' */
.icon-lock::before { content: '\e802'; } /* '' */
.icon-list::before { content: '\e811'; } /* '' */
.icon-info-circled::before { content: '\e806'; } /* '' */
.icon-down-open::before { content: '\e80e'; } /* '' */
.icon-block::before { content: '\e80b'; } /* '' */
.icon-up-open::before { content: '\e80f'; } /* '' */
.icon-right-open::before { content: '\e80d'; } /* '' */
.icon-left-open::before { content: '\e80c'; } /* '' */
.icon-home::before { content: '\e807'; } /* '' */
.icon-mail::before { content: '\e801'; } /* '' */
.icon-spin1::before { content: '\e810'; } /* '' */

/*
   Animation example, for spinners
*/

.animate-spin {
  animation: spin 2s infinite linear;
  display: inline-block;
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
