/******************************************************
RESET
******************************************************/

.cf::before,
.cf::after {
  content: ' ';
  display: table;
}

.cf::after { clear: both; }

.cf { zoom: 1; }

/* CSS reset */

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  border: none;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100%;
  height: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

input {
  border: 1px solid #B0B0B0;
  padding: 3px 5px 4px;
  color: #979797;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q::before,
q::after {
  content: '';
}

abbr,
acronym {
  border: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

#hidden { display: none; }

a { outline: none; }

div#overlay h3.abstand,
div#overlay p.abstand { margin-top: 2em; }
