h1 {
  padding: 0;
  margin: 0;
  position: fixed;
  background: url('images/Luigi-Morini-WortBildMarke_NEG-WWW-01.svg') no-repeat 0 0;
  width: 100px;
  height: 67px;
  bottom: 10%;
  left: 50%;
  margin-left: -50px;
  z-index: 10000;
}

h1 a {
  display: block;
  height: 100px;
}

h1 a:hover {
  opacity: 0.5;
}

/* Logo oben bei Seiten ohne Slideshow */

body.page-template-default h1,
body.page-template-page-map-php h1,
body.category-19 h1,
body.category-23 h1 {
  padding: 0;
  position: static;
  background: url('images/Luigi-Morini-WortBildMarke_NEG-WWW-01.svg') no-repeat center 0;
  width: 100%;
  height: 100px;
  margin: 25px 0;
  z-index: 999;
}

body.page-template-page-map-php h1 {
  background: url('images/Luigi-Morini-WortBildMarke_NEG-WWW-01.svg') no-repeat center 0;
}

@media only screen and (orientation: landscape) and (max-width: 801px) {

  body.home h1 {
    background: none;
    width: 150px;
    height: 100px;
    margin-left: -75px;
  }
}

@media only screen and (orientation: portrait) and (min-width: 641px) {

  h1 {
    width: 200px;
    height: 125px;
    margin-left: -100px;
    visibility: hidden;
  }
}

/* START MOBILE TABLET PORTRAIT - BIG WHITE LOGO */

@media only screen and (orientation: landscape) and (min-width: 801px) {

  h1,
  body.page-template-default h1,
  body.page-template-page-map-php h1,
  body.category-19 h1,
  body.category-23 h1 {
    position: fixed;
    left: 0;
    bottom: inherit;
    top: 50px;
    background: url('images/Luigi-Morini-WortBildMarke_NEG-WWW-01.svg') no-repeat center 0;
    height: 67px;
    width: 12.5em;
    margin: 0;
  }
}

@media only screen and (orientation: landscape) and (min-width: 801px) and (max-height: 599px) {

  h1,
  body.page-template-default h1,
  body.page-template-page-map-php h1,
  body.category-19 h1,
  body.category-23 h1 {
    top: 1.25em;
  }
}

@media only screen and (min-width: 1400px) {

  h1,
  body.home h1,
  body.page-template-default h1,
  body.page-template-page-map-php h1,
  body.category-19 h1,
  body.category-23 h1 {
    width: 18.75em;
    height: 100px;
  }
}

html.ie8 h1,
html.ie8 body.page-template-default h1,
html.ie8 body.page-template-page-map-php h1,
html.ie8 body.category-19 h1,
html.ie8 body.category-23 h1 {
  position: fixed;
  left: 5%;
  bottom: inherit;
  top: 50px;
  background: url('images/Luigi-Morini-WortBildMarke_POS-WWW-01.png') no-repeat center 0;
  height: 67px;
  width: 12.5em;
  margin-left: 0;
}
