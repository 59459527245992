/*****************************************************
Author : orangeadvertising - Felix Zemmel / https://orangeadvertising.de
Date   : 2013.06.04
File   : css/luigimorini-2.css
******************************************************/

/******************************************************
GENERAL
******************************************************/

body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: corporatesttuligregular, Arial, Helvetica, sans-serif;
  font-size: 100%;
  color: #366DB3;
  background: white;
}

body.page-id-53,
body.page-id-54,
body.page-id-55 {
  overflow-y: scroll;
}

body.home img.wp-image-68 {
  display: none;
}

/* AGB */

body.page-id-5643 #overlay h3,
body.page-id-4316 #overlay h3 {
  margin: 2em 0 0;
}

body.page-id-5643 #overlay ol,
body.page-id-4316 #overlay ol {
  list-style: upper-roman;
  margin: 1em 0 1em 2em;
  letter-spacing: 1px;
}

body.page-id-5643 #overlay ol ol,
body.page-id-4316 #overlay ol ol {
  list-style: lower-latin;
}

body.page-id-5643 #overlay ol li,
body.page-id-4316 #overlay ol li {
  margin: 0 0 1em;
  line-height: 1.5;
}

/******************************************************
CONTENT
******************************************************/

#content {
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.content_block {
  margin: 0 0 2.5em;
}

.content_block p,
.content_block ul {
  padding: 0;
  line-height: 1.5;
}

.content_block ul {
  margin: 0 0 1.25em;
}

.lang-item {
  display: inline-block !important;
  padding: 24px 24px 24px 10% !important;
}

h2 {
  font-size: 0.75em;
  text-transform: uppercase;
  padding: 0 10px;
  color: white;
  height: 1.875em;
  line-height: 1.875em;
  letter-spacing: 1px;
}

#overlay h2 {
  font-size: 1.5em;
  padding: 0 0 10px;
  height: auto;
  line-height: 1;
  color: #366DB3;
}

p {
  line-height: 1.5;
  padding: 0 1.25em 1.25em;
  font-size: 1em;
  margin: 0;
  letter-spacing: 1px;
  text-shadow: 1px 1px 3px #2B3B8B;
}

#overlay p {
  padding: 0 0 1.25em;
}

a {
  color: #366DB3;
  text-decoration: underline;
}

p.copy {
  text-align: center;
  font-size: 10px;
  text-shadow: none;
  letter-spacing: 0;
  width: 100%;
  color: white;
}

a#right-menu {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  text-align: center;
  display: inline-block;
  color: white;
  text-decoration: none;
  background: #366DB3;
  background: rgba(54, 109, 179, 1);
  border-radius: 6px 0 0 6px;
}

a#right-menu.active {
  position: absolute;
  right: 0;
  top: 50px;
}

a#right-menu::before,
a#right-menu.active::before {
  font-family: luigimorini-icons;
  font-style: normal;
  font-weight: normal;
  content: '\e806';
  font-size: 30px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  text-shadow: 1px 1px 3px #2B3B8B;
  transition: opacity 0.25s;
}

a#right-menu.active::before {
  content: '\e80d';
}

a#right-menu:hover::before {
  opacity: 0.5;
}

/* MAP SEARCH ICON */

a#right-menu.map::before,
a#right-menu.active.map::before {
  content: '\e800';
}

a#right-menu.active.map::before {
  content: '\e800';
}

.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 12.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background: #366DB3;
  background: rgba(54, 109, 179, 1);
  color: white;
  text-align: left;
}

#map_search {
  right: -15.625em;
  width: 15.625em;
}

.sidr.right,
#map_search {
  left: auto;
  /*right: -12.5em;*/
  border: none;
  position: fixed;
}

#sidr-right {
  right: -12.5em;
}

.sidr h3 {
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 1.25em 20px 0.5em;
  letter-spacing: 2px;
  text-shadow: 1px 1px 3px #2B3B8B;
}

div#overlay {
  width: 90%;
  margin: 5%;
  background: white;
  background: rgba(255, 255, 255, 0.9);
  padding: 1.25em;
  z-index: 11;
  text-align: left;
}

div#overlay p,
div#overlay h3 {
  text-shadow: none;
  margin: 0;
}

div#overlay p,
dl,
address {
  margin: 0 0 1.25em;
}

dt,
dd {
  display: inline-block;
}

dt {
  width: 20%;
}

dd {
  width: 75%;
}

.gallery dl {
  float: left;
  width: 33.334%;
}

.gallery dt {
  width: 100%;
}

#overlay h3 {
  text-transform: uppercase;
}

h4 {
  margin: 10px 0 0;
}

th {
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0 0 10px;
  width: 33%;
}

th.event-time {
  width: 25%;
}

th.event-description {
  width: 50%;
}

td {
  vertical-align: top;
  padding: 0 1.25em 1.25em 0;
}

td.event-time,
span.event-time {
  text-transform: uppercase;
}

span.event-location {
  font-size: 0.875em;
}

input,
select {
  font-family: corporatesttuligregular, Arial, Helvetica, sans-serif;
  padding: 0.5em;
  font-size: 1em;
  -webkit-appearance: none;
}

#location_search_form input,
#location_search_form input[type='submit'],
#location_search_form select {
  width: 100%;
  border: none;
  background: white;
  color: #2B3B8B;
  font-size: 1.25em;
  box-shadow: 1px 1px 3px 0 #2B3B8B;
  -webkit-appearance: none;
}

input#pwbox-2118,
input#pwbox-2119,
input#pwbox-5624,
input#pwbox-5626,
input#pwbox-5647,
#ngg-download-frm input[type='submit'],
#ngg-download-frm input[type='button'] {
  width: 55%;
  box-shadow: none;
  background: white;
  color: #366DB3;
  border: 1px solid #366DB3;
  margin: 0 0 1.25em;
}

body.page-id-2006 input[type='submit'],
#ngg-download-frm input[type='submit'] {
  margin: 0 0 0 1.25em;
  width: 35%;
}

#ngg-download-frm input.button.ngg-download-selectall,
#ngg-download-frm input.button.downloadButton {
  font-family: corporatesttuligregular, Arial, Helvetica, sans-serif;
  font-size: 1em;
  background: #366DB3;
  color: white;
  border: none;
  width: auto;
}

@media only screen and (orientation: portrait) and (max-width: 641px) {

  th.event-category {
    display: none;
  }

  td.event-category {
    display: none;
  }
}

@media only screen and (orientation: landscape) and (min-width: 801px) {

  body {
    border: none;
    position: static;
  }

  body.home #content {
    background-size: auto 100%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .flexslider {
    display: block;
  }

  #swipebox {
    display: none;
  }

  #maximage {
    display: block;
  }

  span.headline {
    display: none;
  }

  /******************************************************
CONTENT
******************************************************/

  h2 {
    font-size: 0.75em;
    text-transform: uppercase;
    padding: 0 10%;
    color: #326CB3;
    height: 1.875em;
    line-height: 1.875em;
    letter-spacing: 1px;
  }

  /*
  body.home a#right-menu.active {

    right: 12.5em;
  }
  */

  .sidr,
  #map_search {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999999;
    width: 12.5em;
    overflow-x: hidden;
    overflow-y: auto;
    background: #366DB3;
    background: rgba(54, 109, 179, 1);
    color: white;
  }

  .sidr.right,
  #map_search {
    left: auto;
    right: -12.5em;
    border: none;
  }

  #map_search {
    right: -15.625em;
    width: 15.625em;
  }

  div#overlay {
    width: 31.25em;
    position: absolute;
    right: 5%;
    top: 0;
    margin: 0;
    min-height: 100%;
    padding: 2.5em 1.25em 1.25em;
    background: white;
    background: rgba(255, 255, 255, 0.9);
  }
}

@media only screen and (orientation: landscape) and (min-width: 801px) and (max-height: 599px) {

  div#overlay {
    padding: 1.25em;
  }
}

@media only screen and (min-width: 1400px) {

  .flex-control-nav,
  .sidr.left,
  .sidr.right,
  #map_search,
  .sidr,
  .nav-center,
  #navigation div#mlp_widget-2 {
    width: 18.75em;
  }

  #sidr-right,
  #map_search {
    right: -18.75em;
  }
}

/* SMARTPHONES QUERFORMAT */
@media only screen and (orientation: landscape) and (max-width: 799px) {

  /*
body.home h1 a {
	background:url(images/logo/Luigi-Morini-White.png) no-repeat center center;background-size:cover
	}
	*/
}

/* RETINA SMARTPHONES */
@media only screen and (max-width: 801px) and (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi) {

  /*	h1 a {background:url(images/logo/Luigi-Morini@2x.png) no-repeat center center;background-size:cover}
	body.home h1 a {background:url(images/logo/Luigi-Morini-White@2x.png) no-repeat center center;background-size:cover}
*/
}

/* RETINA > SMARTPHONES */
@media only screen and (min-width: 801px) and (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi) {

  /* h1 a {background:url(images/logo/Luigi-Morini-XL@2x.png) no-repeat center center;background-size:cover}
body.home h1 a {background:url(images/logo/Luigi-Morini-XL@2x.png) no-repeat center center;background-size:cover}
*/
}

/* Marcello overflow */

.menu-item > a {
  padding-right: 0% !important;
}

div.markertext h3 a {
  cursor: default !important;
  pointer-events: none;
}

.markertext {
  overflow: initial !important;
}

.wpgmza_map,
p {
  text-shadow: none;
}
